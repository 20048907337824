/* animations.css */
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

/* Add any other necessary styles */
.slide-down {
  animation: slideDown 1s ease-out forwards;
}

@keyframes slideInTop {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-right {
  animation: slideInRight 1s ease-out;
}

.slide-in-left {
  animation: slideInLeft 1s ease-out forwards;
}

.slide-in-top {
  animation: slideInTop 1s ease-out forwards;
}

.hidden {
  opacity: 0; /* Ensure the box is hidden when not animated */
  transform: translateY(20px); /* Optional fallback position */
}
